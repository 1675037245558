/// <reference path="../../shared_controllers/socketService.ts" />
/// <reference path="../../shared_controllers/util.ts" />
/// <reference path="../../app.ts" />
/// <reference path="../../shared_controllers/common_controllers.ts" />
/// <reference path="../../services/generic.services.ts" />

class loggingCtrl extends baseEntitiesCtrl{
    static $inject: Array<string> = ['$scope', '$location', '$compile', 'socketService', '$http','$sce'];
    scope:any;
     collapsed : false;
      data: {id:string, name:string}[]
      data2: {id:string, name:string}[]
      items
      logs:any;
      startDate:string ='';
      endDate :string='';
      searchstringUsername:any='';
      logsQuery:any;
      logsCollection:any;
      admin:any;
      genericServices:GenericServiceClass;
    constructor(
        public $scope,
        public $location,
        public $compile,
        public socketService: socketServiceFactory,
        public $http,
        public $sce
        
        )
        {
            
            super($scope, $location, $compile, socketService);
                    this.genericServices = new GenericServiceClass(this.socketService.namespace.socket,this.socketService.namespace)
            this.loading = true;
            //var user = this.socketService.user; 
            var url = "//api.ipify.org/";
            var trust = $sce.trustAsResourceUrl(url);
            $http.jsonp(trust,{params: {format:'jsonp'}})
              .then(function(response) {
                 var h=response.data.ip;
                me.genericServices.addLogRecord2(h);

            }).catch(function(response) { 

            }) 


            this.collection = 'logs';
            this.basequery = ""
               var me=this;
            var applyFilterTypes = [{
                key: "auto",
                name: "Immediately"
            }, {
                key: "onClick",
                name: "On Button Click"
            }],
            dataGrid;
        

        
        $scope.filterRow = {
            visible: true,
            applyFilter: "auto"
        };


        $scope.rowAlternationEnabled = true;
        $scope.showColumnLines = false;
        $scope.showRowLines = true;
        $scope.showBorders = true;
        $scope.rowAlternationEnabled = true;
        
        $scope.gridDataSource = [];
        $scope.gridSettings = {
            onInitialized: function(e) {
                dataGrid = e.component;
            },
            bindingOptions: { dataSource: 'gridDataSource', 
            filterRow: "filterRow",
            headerFilter: "headerFilter",
            rowAlternationEnabled: "rowAlternationEnabled"},
            searchPanel: {
                visible: true,
                width: 240,
                placeholder: "Search..."
            }, 
        
            columns: [ {
                dataField: "username",
                alignment: "right",
                width: 180
            }, {
                dataField: "action",
                width: 180,
                alignment: "right"
            }, {
                caption: "logLevel",
                headerFilter: {
                    allowSearch: true
                }
            }, {
                dataField: "source",
                alignment: "right",
                width: 180
            }, {
                dataField: "machineIp",
                alignment: "right",
                width: 180
            }, {
                dataField: "timeStamp",
                alignment: "right",
                width: 180
            }]
        };

        $scope.filterTypesOptions = {
            items: applyFilterTypes,
            value: applyFilterTypes[0].key,
            valueExpr: "key",
            displayExpr: "name",
            bindingOptions: {
                value: "filterRow.applyFilter",
                disabled: "!filterRow.visible"
            }
        };

        $scope.filterVisibleOptions = {
            text: "Filter Row",
            bindingOptions: {
                value: "filterRow.visible"
            },
            onValueChanged: function(data) {
                dataGrid.clearFilter();
            }
        };

        $scope.headerFilterOptions = {
            text: "Header Filter",
            bindingOptions: {
                value: "headerFilter.visible"
            },
            onValueChanged: function() {
                dataGrid.clearFilter();
            }
        };

        $scope.rowAlternationOptions = {
            text: "Alternating Row Color",
            bindingOptions: {
                value: "rowAlternationEnabled"
            }
        };


        $scope.series333=[];
        $scope.pData1 =[];
        $scope.chartOptions3 = {
                palette: ["#DC143C", "#8B0000"],
                bindingOptions: {
                    dataSource: "pData1",
                    series:"series5",
                    adaptiveLayout: {
                        width:400,
                     keepLabels: true
                }
                },
              
            };

            $scope.barData =  [];

            $scope.barChartOptions1 = {
                bindingOptions: {
                    dataSource: "pData3"
                },
              
                series: {
                    argumentField: "name",
                    valueField: "count",
                    name: "workflows",
                    type: "bar",
                    color: '#45d0e7'
                },
                valueAxis: {  
                    min: 0,  
                    tickInterval: 1
                } ,
                label: {
                    visible: true,
                 
                },
                adaptiveLayout: {
                    width:400,
                 keepLabels: true
            }
               
            };


            $scope.barData =  [];
            $scope.barChartOptions = {
                bindingOptions: {
                    dataSource: "barData",
                    

                },
                adaptiveLayout: {
                    width:400,
                 keepLabels: true
            },
                commonSeriesSettings: {
                    barPadding: 0.5,
                    barWidth:80,
                    type: "bar"
                },
                series: {
                    argumentField: "name",
                    valueField: "count",
                    name: "WorkFlow",
                    type: "bar",
                    color: '#45d0e7'
                },
                valueAxis: {  
                    min: 0,  
                    tickInterval: 1
                } 
            };
            

        $scope.series5=[];
        $scope.pieData5 =  [];
        $scope.chartOptions5 = {
            palette: ['#45d0e7','#3ebbcf','#37a6b8','#3091a1','#297c8a','#226873','#1b535c'],
            bindingOptions: {
                dataSource: "pieData5",
                series:"series5"
            },
            size: {width: 515, height:515} , 
           //title: "No of completed workflows per user"
        };


        $scope.series52=[];
        $scope.pieData52 =  [];
        $scope.chartOptions52 = {
            palette: ["#DC143C", "#8B0000"],
            bindingOptions: {
                dataSource: "pieData52",
                series:"series52"
            },
            size: {width: 500, height: 500} , 
          // title: "No of failed workflows per user"
        };


        $scope.gridData5=[]
        $scope.grouping = {
            autoExpandAll: false
        };
        
        $scope.dataGridOptions5 = {
            allowColumnReordering: true,
            showBorders: true,   
            bindingOptions: {
                dataSource:"gridData5",
                grouping: "grouping"
            },
            searchPanel: {
                visible: true
            },
            paging: {
                pageSize: 20
            },  
            groupPanel: {
                visible: true
            },
            columns: [{
                dataField: "action",
                width: 400,
            },{
                dataField: "logLevel",
                width: 100,
            },{
                dataField: "ipAdress",
                width: 150,
                caption:"Machine Name/Ip"
            },{
                dataField: "timeStamp",
                width: 210,
                dataType: 'date',  
                format: 'dd/MM/yyyy hh:mm',
                caption:"Time"

            },{
                dataField: "source",
                width: 150,
            },
                {
                    dataField: "username",
                    groupIndex: 0,
                    caption:"User Name"
                }
            ]
        };
        $scope.checkBoxOptions = {
            text: "Expand All Groups",
            bindingOptions: {
                value: "grouping.autoExpandAll"
            }
        };
            



    me.socketService.onSignedin(async () => {
    var query="actionType eq 'wf.run' or actionType eq 'wf.fail'"
    this.logsQuery = { $or: [{logLevel: 'info'} , {logLevel: 'error'} ]  };
    this.logsQuery =  {logLevel: 'error'};
    this.logsCollection="logs";
    //    var Result :any = await this.genericServices.getPagingDetails(this.logsQuery, this.pagesize, (this.page - 1) * this.pagesize, null, null, this.logsCollection , true );
    //    this.logs=Result.items;
       var ag=[ 
                 
        { $group: { _id: {logLevel:'$logLevel'}, count: { $sum: 1 } } } 
        
        ]
     var count = await me.genericServices.aggregate(ag,'logs');
     let x = 0;
     let y =0 ;
     (count && count.length >0) ? count[0].count : x =0;
     (count && count.length >1) ? count[1].count : y =0;

   this.totalItems = x+y;
   this.getPagingData();
        var items =await me.genericServices.get(me.basequery, null, null, me.order.field, me.order.reverse, me.collection);     
        var items5 =await me.genericServices.get(query, null, null, me.order.field, me.order.reverse, me.collection);
      
         
        var ag1=[ 
   
            { $match: { "logLevel": "error" } },  
            { $group: { _id: {source:'$source'}, count: { $sum: 1 } } } 
            
            ]
         var count1 = await me.genericServices.aggregate(ag1,'logs');      
        
        var ag2=[ 
   
            { $match: { "actionType": "wf.run" } },  
            { $group: { _id: {workflowId:'$workflowId',metaData:'$metaData'}, count: { $sum: 1 } } } 
            
            ]
         var count2 = await me.genericServices.aggregate(ag2,'logs');

         var ag33=[ 
   
            { $match: { "actionType": "wf.create" } },  
            { $group: { _id: {username:'$username'}, count: { $sum: 1 } } } ,
            {$sort: {count: -1}},
            {$limit: 8}
            
            ]
         var count33 = await me.genericServices.aggregate(ag33,'logs');

         var ag5=[ 
   
            { $match: { "actionType": "wf.run" } },  
            { $group: { _id: {username:'$username'}, count: { $sum: 1 } } } ,
            {$sort: {count: -1}},
            {$limit: 5}
            
            ]
         var count5 = await me.genericServices.aggregate(ag5,'logs');

         var ag52=[ 
   
            { $match: { "actionType": "wf.fail" } },  
            { $group: { _id: {username:'$username'}, count: { $sum: 1 } } } ,
            {$sort: {count: -1}},
            {$limit: 5}
            
            ]
         var count52 = await me.genericServices.aggregate(ag52,'logs');



        setTimeout(function (){ 
           $scope.$apply(function () {
          $scope.gridDataSource = items;

               var piedata=count5.map(res=>({name:res._id.username,count:res.count}));
               var piedata2=count52.map(res=>({name:res._id.username,count:res.count})); 
               var piedataa3=count33.map(res=>({name:res._id.username,count:res.count}));
               var piedataa1=count1.map(res=>({name:res._id.source,count:res.count}));
               $scope.pData1=piedataa1;
               $scope.pData3=piedataa3;
               $scope.series333=[
                   {
                       argumentField: "name",
                       valueField: "count",
                       label: {
                           visible: true,
                           connector: {
                               visible: true,
                               width: 1
                           }
                       }
                   }
               ]

               $scope.pieData5=piedata;
               $scope.series5=[
                {
                    argumentField: "name",
                    valueField: "count",
                    label: {
                        visible: true,
                        connector: {
                            visible: true,
                            width: 1
                        }
                    }
                }
            ]

            $scope.pieData52=piedata2;
            $scope.series52=[
                {
                    argumentField: "name",
                    valueField: "count",
                    label: {
                        visible: true,
                        connector: {
                            visible: true,
                            width: 1
                        }
                    }
                }
            ]

               $scope.gridData5=items5;
              var hoh=count2.map(res=>({name:res._id.metaData,count:res.count})); 
               $scope.barData=hoh; 

     
           });
       }, 0);
   
       setTimeout(function () {
           $scope.$apply(function () {
                var logLevelChart=[{logLevel:count[0]._id.logLevel, count:count[0].count},
               {logLevel:count[1]._id.logLevel, count:count[1].count}];
  
               $scope.chartDataSource = logLevelChart;
           });
       }, 0);
       });

     
    }
    async getPagingData() {
        clearError(this.$scope);
        if (this.collection == 'logs') {
            this.service = this.socketService.namespace;
        }
        // debugger;
        if (!this.genericServices.isConnected()) return;
        this.loading = true;
        //var t0 = performance.now();
        var user:any = this.socketService.user; 
        if(!user.roles.find(R=>R._id == adminsRoleId )){
           this.logsQuery.username = user.username ;
           this.admin =false;
        }
        else
        {
           
            this.admin =true;
        }
        if(this.searchstring == null || this.searchstring == '' ){
            if(this.logsQuery && this.logsQuery) {
                delete this.logsQuery.logLevel;
             }
        }
        
        if (this.searchstring.trim() !== '') {
            if(!this.logsQuery) {this.logsQuery = {} }
            this.logsQuery.logLevel = {$regex : ".*"+this.searchstring +".*" , $options: "i"}
        }
       
        if((this.searchstringUsername == null || this.searchstringUsername == '') && !this.logsQuery.username ){
            if(this.logsQuery && this.logsQuery) {
                delete this.logsQuery.username;
             }
        }
        
        if (this.searchstringUsername.trim() !== '') {
            if(!this.logsQuery) {this.logsQuery = {} }
            this.logsQuery.username = {$regex : ".*"+this.searchstringUsername +".*" , $options: "i"}
        }
        if(this.startDate == null || this.startDate == '' ){
            if(this.logsQuery && this.logsQuery) {
                delete this.logsQuery.timeStamp;
             }
        }

        if(this.startDate && this.endDate){
        if(this.startDate > this.endDate ){
            alert("please supply a valid date");
            delete this.logsQuery.timeStamp;
        }
    }

        if( !this.logsQuery.timeStamp)
        { 
            this.logsQuery.timeStamp={};
        }
     
        if (this.startDate) {
            if(!this.logsQuery) {this.logsQuery = {} ;this.logsQuery.timeStamp={}; }
            // this.startDate=this.startDate.replace('/','-');
           // var date = new Date(this.startDate).toISOString();
           // date = date.split('T')[0];
            this.logsQuery.timeStamp['$gte'] = new Date(this.startDate).setHours(0,0,0,0);//.toISOString()}
        }
        //
        if (this.endDate) {
            if(!this.logsQuery) {this.logsQuery = {} }
            // this.startDate=this.startDate.replace('/','-');
           // var date = new Date(this.startDate).toISOString();
           // date = date.split('T')[0];
            this.logsQuery.timeStamp['$lte'] =  new Date(this.endDate).setHours(23,59,59,999) //.toISOString()}
        }
        if(this.endDate == null && this.startDate==null || this.endDate == '' && this.startDate=='' ){
            if(this.logsQuery && this.logsQuery) {
                delete this.logsQuery.timeStamp;
             }
        }
        
    
        try {
            var Result :any = await this.genericServices.getPagingDetails(this.logsQuery, 20, (this.page - 1) * 20, "timeStamp", this.order.reverse, this.logsCollection , true );
            if(this.logsQuery.username )
            {   delete this.logsQuery.username ;}
          
            this.logs=Result.items;
            // number of records on the page 
            this.pagesize = 20;
            this.totalItems = Result.count;
            // max page number in paggingation
            this.pageCount = Math.ceil(Result.count / this.pagesize);
 
            this.loading = false; 
            this.entities = this.logs; 
            this.scrollloading = (this.logs.length > 0);
            this.loading = false; 
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        } catch (err) {
            handleError(this.$scope, err);
        }
    }
    
    
}
class camoundaCtrl extends baseEntitiesCtrl{
    static $inject: Array<string> = ['$scope', '$location', '$compile', 'socketService', '$http','$sce','toastr'];
    scope:any;
     collapsed : false;
      data: {id:string, name:string}[]
      data2: {id:string, name:string}[]
      items
      logs:any;
      startDate:string ='';
      endDate :string='';
      searchstringUsername:any='';
      logsQuery:any;
      logsCollection:any;
      admin:any;
      genericServices:GenericServiceClass;
      toastrObj :any;
      proccesses:any = [];
    constructor(
        public $scope,
        public $location,
        public $compile,
        public socketService: socketServiceFactory,
        public $http,
        public $sce,
        public toastr
        
        )
        {
            
            super($scope, $location, $compile, socketService);
                    this.genericServices = new GenericServiceClass(this.socketService.namespace.socket,this.socketService.namespace)
            this.loading = true;
            //var user = this.socketService.user; 
            var url = "//api.ipify.org/";
            var trust = $sce.trustAsResourceUrl(url);
            this.toastrObj = toastr;
            $http.jsonp(trust,{params: {format:'jsonp'}})
              .then(function(response) {
                 var h=response.data.ip;
                me.genericServices.addLogRecord2(h);

            }).catch(function(response) { 

            }) 

        
            // get instances data
          this.getInstancesStatus();
         

            this.collection = 'logs';
            this.basequery = ""
               var me=this;
            var applyFilterTypes = [{
                key: "auto",
                name: "Immediately"
            }, {
                key: "onClick",
                name: "On Button Click"
            }],
            dataGrid;
        

        
        $scope.filterRow = {
            visible: true,
            applyFilter: "auto"
        };


        $scope.rowAlternationEnabled = true;
        $scope.showColumnLines = false;
        $scope.showRowLines = true;
        $scope.showBorders = true;
        $scope.rowAlternationEnabled = true;
        
        $scope.gridDataSource = [];
        $scope.gridSettings = {
            onInitialized: function(e) {
                dataGrid = e.component;
            },
            bindingOptions: { dataSource: 'gridDataSource', 
            filterRow: "filterRow",
            headerFilter: "headerFilter",
            rowAlternationEnabled: "rowAlternationEnabled"},
            searchPanel: {
                visible: true,
                width: 240,
                placeholder: "Search..."
            }, 
        
            columns: [ {
                dataField: "username",
                alignment: "right",
                width: 180
            }, {
                dataField: "action",
                width: 180,
                alignment: "right"
            }, {
                caption: "logLevel",
                headerFilter: {
                    allowSearch: true
                }
            }, {
                dataField: "source",
                alignment: "right",
                width: 180
            }, {
                dataField: "machineIp",
                alignment: "right",
                width: 180
            }, {
                dataField: "timeStamp",
                alignment: "right",
                width: 180
            }]
        };

        $scope.filterTypesOptions = {
            items: applyFilterTypes,
            value: applyFilterTypes[0].key,
            valueExpr: "key",
            displayExpr: "name",
            bindingOptions: {
                value: "filterRow.applyFilter",
                disabled: "!filterRow.visible"
            }
        };

        $scope.filterVisibleOptions = {
            text: "Filter Row",
            bindingOptions: {
                value: "filterRow.visible"
            },
            onValueChanged: function(data) {
                dataGrid.clearFilter();
            }
        };

        $scope.headerFilterOptions = {
            text: "Header Filter",
            bindingOptions: {
                value: "headerFilter.visible"
            },
            onValueChanged: function() {
                dataGrid.clearFilter();
            }
        };

        $scope.rowAlternationOptions = {
            text: "Alternating Row Color",
            bindingOptions: {
                value: "rowAlternationEnabled"
            }
        };


        $scope.series333=[];
        $scope.pData1 =[];
        $scope.chartOptions3 = {
                palette: ["#DC143C", "#8B0000"],
                bindingOptions: {
                    dataSource: "pData1",
                    series:"series5",
                    adaptiveLayout: {
                        width:400,
                     keepLabels: true
                }
                },
              
            };

            $scope.barData =  [];

            $scope.barChartOptions1 = {
                bindingOptions: {
                    dataSource: "pData3"
                },
              
                series: {
                    argumentField: "name",
                    valueField: "count",
                    name: "workflows",
                    type: "bar",
                    color: '#45d0e7'
                },
                valueAxis: {  
                    min: 0,  
                    tickInterval: 1
                } ,
                label: {
                    visible: true,
                 
                },
                adaptiveLayout: {
                    width:400,
                 keepLabels: true
            }
               
            };


            $scope.barData =  [];
            $scope.barChartOptions = {
                bindingOptions: {
                    dataSource: "barData",
                    

                },
                adaptiveLayout: {
                    width:400,
                 keepLabels: true
            },
                commonSeriesSettings: {
                    barPadding: 0.5,
                    barWidth:80,
                    type: "bar"
                },
                series: {
                    argumentField: "name",
                    valueField: "count",
                    name: "WorkFlow",
                    type: "bar",
                    color: '#45d0e7'
                },
                valueAxis: {  
                    min: 0,  
                    tickInterval: 1
                } 
            };
            

        $scope.series5=[];
        $scope.pieData5 =  [];
        $scope.chartOptions5 = {
            palette: ['#45d0e7','#3ebbcf','#37a6b8','#3091a1','#297c8a','#226873','#1b535c'],
            bindingOptions: {
                dataSource: "pieData5",
                series:"series5"
            },
            size: {width: 515, height:515} , 
           //title: "No of completed workflows per user"
        };


        $scope.series52=[];
        $scope.pieData52 =  [];
        $scope.chartOptions52 = {
            palette: ["#DC143C", "#8B0000"],
            bindingOptions: {
                dataSource: "pieData52",
                series:"series52"
            },
            size: {width: 500, height: 500} , 
          // title: "No of failed workflows per user"
        };


        $scope.gridData5=[]
        $scope.grouping = {
            autoExpandAll: false
        };
        
        $scope.dataGridOptions5 = {
            allowColumnReordering: true,
            showBorders: true,   
            bindingOptions: {
                dataSource:"gridData5",
                grouping: "grouping"
            },
            searchPanel: {
                visible: true
            },
            paging: {
                pageSize: 20
            },  
            groupPanel: {
                visible: true
            },
            columns: [{
                dataField: "action",
                width: 400,
            },{
                dataField: "logLevel",
                width: 100,
            },{
                dataField: "ipAdress",
                width: 150,
                caption:"Machine Name/Ip"
            },{
                dataField: "timeStamp",
                width: 210,
                dataType: 'date',  
                format: 'dd/MM/yyyy hh:mm',
                caption:"Time"

            },{
                dataField: "source",
                width: 150,
            },
                {
                    dataField: "username",
                    groupIndex: 0,
                    caption:"User Name"
                }
            ]
        };
        $scope.checkBoxOptions = {
            text: "Expand All Groups",
            bindingOptions: {
                value: "grouping.autoExpandAll"
            }
        };
            



    me.socketService.onSignedin(async () => {
    var query="actionType eq 'wf.run' or actionType eq 'wf.fail'"
    this.logsQuery = { $or: [{logLevel: 'info'} , {logLevel: 'error'} ]  };
    this.logsQuery =  {logLevel: 'error'};
    this.logsCollection="logs";
    //    var Result :any = await this.genericServices.getPagingDetails(this.logsQuery, this.pagesize, (this.page - 1) * this.pagesize, null, null, this.logsCollection , true );
    //    this.logs=Result.items;
       var ag=[ 
                 
        { $group: { _id: {logLevel:'$logLevel'}, count: { $sum: 1 } } } 
        
        ]
     var count = await me.genericServices.aggregate(ag,'logs');
     let x = 0;
     let y =0 ;
     (count && count.length >0) ? count[0].count : x =0;
     (count && count.length >1) ? count[1].count : y =0;

   this.totalItems = x+y;
   this.getPagingData();
        var items =await me.genericServices.get(me.basequery, null, null, me.order.field, me.order.reverse, me.collection);     
        var items5 =await me.genericServices.get(query, null, null, me.order.field, me.order.reverse, me.collection);
      
         
        var ag1=[ 
   
            { $match: { "logLevel": "error" } },  
            { $group: { _id: {source:'$source'}, count: { $sum: 1 } } } 
            
            ]
         var count1 = await me.genericServices.aggregate(ag1,'logs');      
        
        var ag2=[ 
   
            { $match: { "actionType": "wf.run" } },  
            { $group: { _id: {workflowId:'$workflowId',metaData:'$metaData'}, count: { $sum: 1 } } } 
            
            ]
         var count2 = await me.genericServices.aggregate(ag2,'logs');

         var ag33=[ 
   
            { $match: { "actionType": "wf.create" } },  
            { $group: { _id: {username:'$username'}, count: { $sum: 1 } } } ,
            {$sort: {count: -1}},
            {$limit: 8}
            
            ]
         var count33 = await me.genericServices.aggregate(ag33,'logs');

 
         var count5 =  await me.genericServices.aggregate(ag33,'logs');// this.instancesChartData;

         var ag52=[ 
   
            { $match: { "actionType": "wf.fail" } },  
            { $group: { _id: {username:'$username'}, count: { $sum: 1 } } } ,
            {$sort: {count: -1}},
            {$limit: 5}
            
            ]
         var count52 = await me.genericServices.aggregate(ag52,'logs');



        setTimeout(function (){ 
           $scope.$apply(function () {
          $scope.gridDataSource = items;

               var piedata=count5.map(item=>({name:item.instance_status,count:item.length}));
               var piedata2=count52.map(res=>({name:res._id.username,count:res.count})); 
               var piedataa3=count33.map(res=>({name:res._id.username,count:res.count}));
               var piedataa1=count1.map(res=>({name:res._id.source,count:res.count}));
               $scope.pData1=piedataa1;
               $scope.pData3=piedataa3;
               $scope.series333=[
                   {
                       argumentField: "name",
                       valueField: "count",
                       label: {
                           visible: true,
                           connector: {
                               visible: true,
                               width: 1
                           }
                       }
                   }
               ]

               $scope.pieData5=piedata;
               $scope.series5=[
                {
                    argumentField: "name",
                    valueField: "count",
                    label: {
                        visible: true,
                        connector: {
                            visible: true,
                            width: 1
                        }
                    }
                }
            ]

            $scope.pieData52=piedata2;
            $scope.series52=[
                {
                    argumentField: "name",
                    valueField: "count",
                    label: {
                        visible: true,
                        connector: {
                            visible: true,
                            width: 1
                        }
                    }
                }
            ]

               $scope.gridData5=items5;
              var hoh=count2.map(res=>({name:res._id.metaData,count:res.count})); 
               $scope.barData=hoh; 

     
           });
       }, 0);
   
       setTimeout(function () {
           $scope.$apply(function () {
                var logLevelChart=[{logLevel:count[0]._id.logLevel, count:count[0].count},
               {logLevel:count[1]._id.logLevel, count:count[1].count}];
  
               $scope.chartDataSource = logLevelChart;
           });
       }, 0);
       });

     
    }
    async getPagingData() {
        clearError(this.$scope);
        if (this.collection == 'logs') {
            this.service = this.socketService.namespace;
        }
        // debugger;
        if (!this.genericServices.isConnected()) return;
        this.loading = true;
        //var t0 = performance.now();
        var user:any = this.socketService.user; 
        if(!user.roles.find(R=>R._id == adminsRoleId )){
           this.logsQuery.username = user.username ;
           this.admin =false;
        }
        else
        {
           
            this.admin =true;
        }
        if(this.searchstring == null || this.searchstring == '' ){
            if(this.logsQuery && this.logsQuery) {
                delete this.logsQuery.logLevel;
             }
        }
        
        if (this.searchstring.trim() !== '') {
            if(!this.logsQuery) {this.logsQuery = {} }
            this.logsQuery.logLevel = {$regex : ".*"+this.searchstring +".*" , $options: "i"}
        }
       
        if((this.searchstringUsername == null || this.searchstringUsername == '') && !this.logsQuery.username ){
            if(this.logsQuery && this.logsQuery) {
                delete this.logsQuery.username;
             }
        }
        
        if (this.searchstringUsername.trim() !== '') {
            if(!this.logsQuery) {this.logsQuery = {} }
            this.logsQuery.username = {$regex : ".*"+this.searchstringUsername +".*" , $options: "i"}
        }
        if(this.startDate == null || this.startDate == '' ){
            if(this.logsQuery && this.logsQuery) {
                delete this.logsQuery.timeStamp;
             }
        }

        if(this.startDate && this.endDate){
        if(this.startDate > this.endDate ){
            alert("please supply a valid date");
            delete this.logsQuery.timeStamp;
        }
    }

        if( !this.logsQuery.timeStamp)
        { 
            this.logsQuery.timeStamp={};
        }
     
        if (this.startDate) {
            if(!this.logsQuery) {this.logsQuery = {} ;this.logsQuery.timeStamp={}; }
            // this.startDate=this.startDate.replace('/','-');
           // var date = new Date(this.startDate).toISOString();
           // date = date.split('T')[0];
            this.logsQuery.timeStamp['$gte'] = new Date(this.startDate).setHours(0,0,0,0);//.toISOString()}
        }
        //
        if (this.endDate) {
            if(!this.logsQuery) {this.logsQuery = {} }
            // this.startDate=this.startDate.replace('/','-');
           // var date = new Date(this.startDate).toISOString();
           // date = date.split('T')[0];
            this.logsQuery.timeStamp['$lte'] =  new Date(this.endDate).setHours(23,59,59,999) //.toISOString()}
        }
        if(this.endDate == null && this.startDate==null || this.endDate == '' && this.startDate=='' ){
            if(this.logsQuery && this.logsQuery) {
                delete this.logsQuery.timeStamp;
             }
        }
        
    
        try {
            var Result :any = await this.genericServices.getPagingDetails(this.logsQuery, 20, (this.page - 1) * 20, "timeStamp", this.order.reverse, this.logsCollection , true );
            if(this.logsQuery.username )
            {   delete this.logsQuery.username ;}
          
            this.logs=Result.items;
            // number of records on the page 
            this.pagesize = 20;
            this.totalItems = Result.count;
            // max page number in paggingation
            this.pageCount = Math.ceil(Result.count / this.pagesize);
 
            this.loading = false; 
            this.entities = this.logs; 
            this.scrollloading = (this.logs.length > 0);
            this.loading = false; 
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        } catch (err) {
            handleError(this.$scope, err);
        }
    }
   
    async getInstancesStatus(){
        
        let data = { };

        try {
            let res :any = await this.$http.post('/get_processes', data);
           
            this.proccesses = res.data.items;

            for (let i = 0; i < this.proccesses .length; i++) {

                let body = {"id":this.proccesses[i].bpmnProcessId}

                let proccess :any = await this.$http.post('/get_last_processes_instance', body);
               
                if(proccess.data.state == "ACTIVE"){
                    this.proccesses[i].status = "PENDING";
                }else{
                    this.proccesses[i].status = proccess.data.state;
                }
             
                this.proccesses[i].url = "https://dsm-1.operate.camunda.io/7e8b9aba-7f4b-43fd-bab0-cb28509a4748/processes/"+ proccess.data.key;
                
                
            }
           
            // if(res.data && res.data.items.length > 0){

            //   let canceled_instances = res.data.items.filter( item => item.state === "CANCELED").length;
            //   this.instancesChartData.push({"instance_status":"CANCELED","length":canceled_instances})
            //   let active_instances = res.data.items.filter( item => item.state === "ACTIVE").length;
            //   this.instancesChartData.push({"instance_status":"ACTIVE","length":active_instances})
            //   let completed_instances = res.data.items.filter( item => item.state === "COMPLETED").length;
            //   this.instancesChartData.push({"instance_status":"COMPLETED","length":completed_instances})
            //   console.log(this.instancesChartData);

            // }
            

        } catch (error) {
            this.toastrObj.error(GetErrorMessage(error.data));
        }

        // this.$http.post('/process-instances', data).then((res) => {
        //   return res;

        // }).catch(err=>{
        //    if(err.status && err.status ===5000){
        //        return err;
        //    } 
        // })
    }
    
}

class reportsCtrl {
    static $inject: Array<string> = ['$scope', '$location', 'socketService','toastr'];
    monthNames:string[] = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];
    //var eventlabels = ['Sikkerheds brist', 'Anmodning om sletning', 'Anmodning om indsigt'];
    //var baseColors = [ '#803690', '#00ADF9', '#DCDCDC', '#46BFBD', '#FDB45C', '#949FB1', '#4D5360'];
    //var baseColors = [ '#97BBCD', '#DCDCDC', '#F7464A', '#46BFBD', '#FDB45C', '#949FB1', '#4D5360' ];
    baseColors:string[] = ['#F7464A', '#97BBCD', '#FDB45C', '#46BFBD', '#949FB1', '#4D5360'];
    //var chartTypes = ['bar', 'line', 'bar', 'horizontalBar', 'radar', 'doughnut', 'pie', 'polarArea', 'bubble'];
    // days    86400000
    // hours   3600000
    // minutes 60000
    // seconds 1000
    options: any = {
        legend: { display: true }
    };
    colors:string[] = this.baseColors;
    type: string = 'bar';
    labels: string[];
    series: string[];
    data: any[];
    genericServices:GenericServiceClass;
    options2: any = {
        legend: { display: true }
    };
    colors2: string[] = this.baseColors;
    type2 = 'bar';
    labels2: string[];
    series2: string[];
    data2: any[];

    loading: boolean = false;
    toastrObj :any;

    constructor (
        public $scope,
        public $location,
        public socketService:socketServiceFactory,
        public toastr
    ){
        this.loading = true;
        this.socketService.onSignedin(()=>{
            this.loaddataOverview();
            this.loaddataTimetaken();
        });
        this.toastrObj = toastr;
        this.genericServices = new GenericServiceClass(this.socketService.namespace.socket,this.socketService.namespace)
        this.loading = false;
    }
 
    async loaddataOverview() {
        var aggregates = [
            { $match: { $and: [{ "completed": { "$exists": true, "$type": 9 } }, { _type: { $ne: 'system' } }] } },
            {
                "$project": {
                    "y": { $year: "$completed" },
                    "m": { $month: "$completed" },
                    "d": { $dayOfMonth: "$completed" },
                    "type": "$_type"
                }
            },
            {
                $group: {
                    _id: { "year": "$y", "month": "$m", "type": "$type" },
                    count: { $sum: 1 }
                }
            }
        ];
        try {
            var items = await this.genericServices.aggregate(aggregates, 'assets');
            var series:any = [];
            var labels:any = [];
            var lowest = Number.POSITIVE_INFINITY;
            var highest = Number.NEGATIVE_INFINITY;
            items.forEach((item)=>{
                var type = item._id.type;
                if (series.indexOf(type) == -1) {
                    series.push(type);
                }
                if (item._id.month < lowest)
                    lowest = item._id.month;
                if (item._id.month > highest)
                    highest = item._id.month;
            }, this);
            for (var i = lowest; i <= highest; i++) {
                labels.push(this.monthNames[i - 1]);
            }
            var data:any = [];
            series.forEach((serie)=>{
                var tmpdata:any = [];
                for (var i = lowest; i <= highest; i++) {
                    var value = 0;
                    items.forEach((item)=>{
                        if (item._id.type == serie && item._id.month == i) {
                            value = item.count;
                        }
                    });
                    tmpdata.push(value);
                }
                data.push(tmpdata);
            }, this);
            series.forEach((type, index)=>{
                if (type == 'breach_request') {
                    series[index] = 'Sikkerheds brist';
                }
                else if (type == 'insight_request') {
                    series[index] = 'Anmodning om indsigt';
                }
                else if (type == 'delete_request') {
                    series[index] = 'Anmodning om sletning';
                }
            }, this);
            this.options.legend.display = true;
            this.type = 'bar';
            this.labels = labels;
            this.series = series;
            this.colors = this.baseColors;
            this.data = data;
            this.loading = false;
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));
        }
    }
    async loaddatamonthview(monthname) {
        var month = this.monthNames.indexOf(monthname) + 1;
        var aggregates = [
            { $match: { $and: [{ "completed": { "$exists": true, "$type": 9 } }, { _type: { $ne: 'system' } }] } },
            {
                "$project": {
                    "y": { $year: "$completed" },
                    "m": { $month: "$completed" },
                    "d": { $dayOfMonth: "$completed" },
                    "type": "$_type"
                }
            },
            { $match: { "m": month } },
            {
                $group: {
                    _id: { "year": "$y", "month": "$m", "day": "$d", "type": "$type" },
                    count: { $sum: 1 }
                }
            }
        ];
        try {
            var items = await this.genericServices.aggregate(aggregates, 'assets');
            var series:string[] = [];
            var labels:string[] = [];
            var lowest = Number.POSITIVE_INFINITY;
            var highest = Number.NEGATIVE_INFINITY;
            items.forEach((item)=>{
                var type = item._id.type;
                if (series.indexOf(type) == -1) {
                    series.push(type);
                }
                if (item._id.day < lowest)
                    lowest = item._id.day;
                if (item._id.day > highest)
                    highest = item._id.day;
            }, this);
            for (var i = lowest; i <= highest; i++) {
                labels.push(i + ' ' + monthname);
            }
            var data:any = [];
            series.forEach((serie)=>{
                var tmpdata:any = [];
                for (var i = lowest; i <= highest; i++) {
                    var value = 0;
                    items.forEach((item)=>{
                        if (item._id.type == serie && item._id.day == i) {
                            value = item.count;
                        }
                    });
                    tmpdata.push(value);
                }
                data.push(tmpdata);
            }, this);
            series.forEach((type, index)=>{
                if (type == 'breach_request') {
                    series[index] = 'Sikkerheds brist';
                }
                else if (type == 'insight_request') {
                    series[index] = 'Anmodning om indsigt';
                }
                else if (type == 'delete_request') {
                    series[index] = 'Anmodning om sletning';
                }
            }, this);
            this.options.legend.display = true;
            this.type = 'bar';
            this.labels = labels;
            this.series = series;
            this.colors = this.baseColors;
            this.data = data;
            this.loading = false;
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));
        }
    }


    async loaddataTimetaken() {
        var aggregates = [
            { $match: { $and: [{ "started": { "$exists": true, "$type": 9 } }, { _type: { $ne: 'system' } }] } },
            {
                "$project": {
                    hours: { $divide: [{ $subtract: ["$completed", "$started"] }, 3600000] },
                    seconds: { $divide: [{ $subtract: ["$completed", "$started"] }, 1000] },
                    "m": { $month: "$completed" },
                }
            },
            {
                $group: {
                    _id: { "month": "$m", "_type": "_type" },
                    count: { $sum: 1 },
                    avg: { $avg: "$seconds" },
                    min: { $min: "$seconds" },
                    max: { $max: "$seconds" }
                }
            }
        ];
        try {
            var items = await this.genericServices.aggregate(aggregates, 'assets');
            var series:any = [];
            var labels:any = [];
            var lowest = Number.POSITIVE_INFINITY;
            var highest = Number.NEGATIVE_INFINITY;
            series = ['fastest in seconds', 'average in seconds', 'maximum in seconds'];
            items.forEach((item)=>{
                // var type = item._id.type;
                // if(series.indexOf(type)==-1) { series.push(type); }
                if (item._id.month < lowest)
                    lowest = item._id.month;
                if (item._id.month > highest)
                    highest = item._id.month;
            }, this);
            for (var i = lowest; i <= highest; i++) {
                labels.push(this.monthNames[i - 1]);
            }
            var data :any= [];
            series.forEach((serie, index)=>{
                if(serie) {}
                var tmpdata :any= [];
                for (var i = lowest; i <= highest; i++) {
                    var value = 0;
                    items.forEach((item)=>{
                        if (index == 0 && item._id.month == i) {
                            value = item.min;
                        }
                        else if (index == 1 && item._id.month == i) {
                            value = item.avg;
                        }
                        else if (index == 2 && item._id.month == i) {
                            value = item.max;
                        }
                    });
                    tmpdata.push(value);
                }
                data.push(tmpdata);
            }, this);
            this.options.legend.display = true;
            this.type2 = 'bar';
            this.labels2 = labels;
            this.series2 = series;
            this.colors2 = this.baseColors;
            this.data2 = data;
            this.loading = false;
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));
        }
    }
    async loaddataTimetakenPerDay(monthname) {
        var month = this.monthNames.indexOf(monthname) + 1;
        var aggregates = [
            { $match: { $and: [{ "started": { "$exists": true, "$type": 9 } }, { _type: { $ne: 'system' } }] } },
            {
                "$project": {
                    hours: { $divide: [{ $subtract: ["$completed", "$started"] }, 3600000] },
                    seconds: { $divide: [{ $subtract: ["$completed", "$started"] }, 1000] },
                    "y": { $year: "$completed" },
                    "m": { $month: "$completed" },
                    "d": { $dayOfMonth: "$completed" }
                }
            },
            { $match: { "m": month } },
            {
                $group: {
                    _id: { "year": "$y", "month": "$m", "day": "$d", "_type": "_type" },
                    count: { $sum: 1 },
                    avg: { $avg: "$seconds" },
                    min: { $min: "$seconds" },
                    max: { $max: "$seconds" }
                }
            }
        ];
        try {
            var items = await this.genericServices.aggregate(aggregates, 'assets');
            var series:any = [];
            var labels:any = [];
            var lowest = Number.POSITIVE_INFINITY;
            var highest = Number.NEGATIVE_INFINITY;
            series = ['fastest in seconds', 'average in seconds', 'maximum in seconds'];
            items.forEach((item)=>{
                //var type = item._id.type;
                //if(series.indexOf(type)==-1) { series.push(type); }
                if (item._id.day < lowest)
                    lowest = item._id.day;
                if (item._id.day > highest)
                    highest = item._id.day;
            }, this);
            for (var i = lowest; i <= highest; i++) {
                labels.push(i + ' ' + monthname);
            }
            var data:any = [];
            series.forEach((serie, serieindex)=>{
                if(serie) {}
                var tmpdata:any = [];
                for (var i = lowest; i <= highest; i++) {
                    var value = 0;
                    items.forEach((item)=>{
                        if (serieindex == 0 && item._id.day == i) {
                            value = item.min;
                        }
                        else if (serieindex == 1 && item._id.day == i) {
                            value = item.avg;
                        }
                        else if (serieindex == 2 && item._id.day == i) {
                            value = item.max;
                        }
                    });
                    tmpdata.push(value);
                }
                data.push(tmpdata);
            }, this);
            this.options2.legend.display = true;
            this.type = 'bar';
            this.labels2 = labels;
            this.series2 = series;
            this.colors2 = this.baseColors;
            this.data2 = data;
            this.loading = false;
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));
        }
    }


    chartclick(charts, e, chart) {
        if(charts && e) {}
        if (!chart) { return; }
        var pagecontent = angular.element(document.querySelector('.pagecontent'));
        var _scope = pagecontent.scope() as any;
        var ctrl = _scope.ctrl as reportsCtrl;
        
        // var index = chart._index;
        // var datasetIndex = chart._datasetIndex;
        if (ctrl.monthNames.indexOf(chart._model.label) > -1) {
            ctrl.loaddatamonthview(chart._model.label);
        }
        else {
            ctrl.loaddataOverview();
        }
    }
    chartclick2(charts, e, chart) {
        if(charts && e) {}
        if (!chart) { return; }
        var pagecontent = angular.element(document.querySelector('.pagecontent'));
        var _scope = pagecontent.scope() as any;
        var ctrl = _scope.ctrl as reportsCtrl;
        // var index = chart._index;
        // var datasetIndex = chart._datasetIndex;
        if (ctrl.monthNames.indexOf(chart._model.label) > -1) {
            ctrl.loaddataTimetakenPerDay(chart._model.label);
        }
        else {
            ctrl.loaddataTimetaken();
        }
    }



    loadonetestitem(systemname, owner, type, started, completed) {
        var model = {
            "systems": [
                {
                    "systemid": "59f4bfc13a262d0012c61d1c",
                    "systemname": systemname,
                    "iscomplete": true,
                    "instruction": "Handling ved en sikkerheds brist",
                    "name": "Sikkerheds brist -> System support -> New system",
                    "owner": true,
                    "ownercompleted": completed,
                    "ownercompletedby": owner,
                    "ownercompletedbyid": "59f1f70af0a22200126638d9"
                }
            ],
            "name": owner + " / Sikkerheds brist",
            "_type": type,
            "completed": completed,
            "started": started,
            "_sample": true
        };
        return this.genericServices.post(model, 'assets');
    };
    randomDate(start, end) {
        return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
    }
    getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max + 1);
        return Math.floor(Math.random() * (max - min)) + min; //The maximum is inclusive
    }
    types = ['breach_request', 'insight_request', 'delete_request'];
    loadtestdata() {
        this.loading = true;
        var promises:any = [];
        var year = 2017;
        for (var month = 1; month <= 12; month++) {
            var numDays = getRandomInt(2, 45);
            for (var _day = 1; _day <= numDays; _day++) {
                var d = getRandomInt(0, 28);
                var begin = new Date(year, month, d);
                var end = new Date(year, month, d);
                end.setDate(begin.getDate() + 1);
                var started = this.randomDate(begin, end);
                var completed = new Date(started);
                completed.setSeconds(completed.getSeconds() + getRandomInt(25, 500));
                var systemname = 'Microsoft CRM';
                var type = this.types[getRandomInt(0, 2)];
                var owner = 'allan@zenamic.dk';
                promises.push(this.loadonetestitem(owner, systemname, type, started, completed));
            }
        }
        Promise.all(promises).then(() => {
            this.loaddataOverview();
            this.loaddataTimetaken();
        });
    };
    deleteitem (id) {
        return this.genericServices.delete(id, 'assets');
    };
    async removetestdata() {
        this.loading = true;
        var promises:any = [];
        var query = '_sample eq true';
        try {
            var items = await this.genericServices.get(query, 1000, 0, '_created', false, 'assets');
            items.forEach((item)=>{
                promises.push(this.deleteitem(item._id));
            },this);
            Promise.all(promises).then(() => {
                this.loaddataOverview();
                this.loaddataTimetaken();
            });
        } catch (err) {
            handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));
        }
    }
}
